import Vue from "vue";
import Router from "vue-router";
import Home from "./views/Home.vue";
import Login from "./views/Login/Login.vue";
import Services from "./views/Services/Services.vue";
import Employees from "./views/Employees/Employees.vue";
import Slots from "./views/Slots/Slots.vue";
import Basket from "./views/Basket/Basket.vue";
import Finish from "./views/Finish/Finish.vue";
import PaymentFinish from "./views/Finish/PaymentFinish.vue";
import Registration from "./views/Registration/Registration.vue";
import NewPassword from "./views/NewPassword/NewPassword.vue";
import NewEmail from "./components/NewEmail/NewEmail.vue";
import DataPreferences from "./components/DataPreferences/DataPreferences.vue";
import YourDetails from "./components/YourDetails/YourDetails.vue";
import Gratuity from "./views/Gratuity/Gratuity.vue";
import Timeout from "./views/Timeout/Timeout.vue";
import ReturningClient from "./views/ReturningClient/ReturningClient.vue";
import YourAppointments from "./views/YourAppointments/YourAppointments.vue";
import Auth from "./views/Auth/Auth";
import Vouchers from "./views/Vouchers/Vouchers.vue";
import VoucherFormDetail from "./views/Vouchers/VoucherFormDetails.vue";
import VoucherCheckout from "./views/Vouchers/VoucherCheckout.vue";
import VoucherFinish from "./views/Vouchers/VoucherFinish.vue";

Vue.use(Router);

export default new Router({
  routes: [
    {
      path: "/",
      name: "home",
      component: Home
    },
    {
      path: "/vouchers",
      name: "vouchers",
      component: () => import('./views/Vouchers/Vouchers.vue')
    },
    {
      path: "/voucher-detail",
      name: "voucher-detail",
      component: VoucherFormDetail
    },
    {
      path: "/voucher-checkout",
      name: "voucher-checkout",
      component: VoucherCheckout
    },
    {
      path: "/voucher-finish",
      name: "voucher-finish",
      component: VoucherFinish
    },
    {
      path: "/your-vouchers",
      name: "your-vouchers",
      component: () => import('./views/Vouchers/YourVouchers.vue')
    },
    {
      path: "/services",
      name: "services",
      component: Services
    },
    {
      path: "/staff",
      name: "staff",
      component: Employees
    },
    {
      path: "/slots",
      name: "slots",
      component: Slots
    },
    {
      path: "/pre-save",
      name: "pre-save",
      component: Basket
    },
    {
      path: "/auth",
      name: "auth",
      component: Auth,
      props: true,
      exact: true
    },
    {
      path: "/logged-out",
      name: "logout",
      component: Login,
      props: true,
      exact: true
    },
    {
      path: "/logged-out-now",
      name: "logged-out",
      component: Login,
      props: true,
      exact: true
    },
    {
      path: "/login",
      name: "login",
      component: Login,
      props: true,
      exact: true
    },
    {
      path: "/registration",
      name: "registration",
      component: Registration,
      props: true,
      exact: true
    },
    {
      path: "/gratuity",
      name: "gratuity",
      component: Gratuity
    },
    {
      path: "/booking-created",
      name: "booking-created",
      component: Finish
    },
    {
      path: "/thank-you",
      name: "thank-you",
      component: PaymentFinish
    },
    {
      path: "/returning-client",
      name: "returning-client",
      component: ReturningClient
    },
    {
      path: "/returning-client/staff",
      name: "returning-client-staff",
      component: ReturningClient
    },
    {
      path: "/new-password",
      name: "new-password",
      component: NewPassword
    },
    {
      path: "/new-email",
      name: "new-email",
      component: NewEmail
    },
    {
      path: "/your-appointments",
      name: "your-appointments",
      component: YourAppointments
    },
    {
      path: "/data-preferences",
      name: "data-preferences",
      component: DataPreferences
    },
    {
      path: "/your-details",
      name: "your-details",
      component: YourDetails
    },
    {
      path: "/timeout",
      name: "timeout",
      component: Timeout
    },
    {
      path: "/*",
      redirect: { name: "home" }
    }
  ],
  // mode: "history",
  // mode: 'abstract',
  linkActiveClass: "active"
});
