<template>
  <b-container>
    <div v-if="isLoading && !vouchers.length" class="vi-voucher__loader">
      <vi-spinner />
    </div>
    <div v-else class="vi-voucher">
      <div class="vi-voucher__header">
        <template v-if="!!voucherInfo.vouchers_enable_discounts">
          <template v-if="voucherInfo.vouchers_additional_amount_type === 'value'">
            <div class="title">
              {{$t('wi_voucher_extra_value_title', {percent: voucherInfo.vouchers_additional_amount + '%'})}}
            </div>
            <div class="subtitle">{{$t('voucher_extra_value_subtitle')}}</div>
          </template>
          <template v-else>
            <div class="title">
              {{$t('wi_voucher_discount_title', {percent: voucherInfo.vouchers_additional_amount + '%'})}}
            </div>
            <div class="subtitle">{{$t('voucher_on_all_gift_cards')}}</div>
          </template>
        </template>
        <template v-else>
          <div class="title">{{$t('voucher_title_without_discount')}}</div>
        </template>
      </div>
      <div class="vi-voucher__body">
        <div v-for="voucher in vouchers" :key="voucher.id" class="vi-voucher-card" :class="{'vi-voucher-card_active': voucher.active}">
          <div class="vi-voucher-card__main">
            <span class="price">{{voucher.amount | priceFormat(getPriceFormat)}}</span>
            <span v-if="!!voucherInfo.vouchers_enable_discounts" class="label-price">{{$t("wi_voucher_you_pay", {amount: voucher.price_formatted})}}</span>
          </div>
          <div class="vi-voucher-card__tools">
            <span class="pay-price">{{voucher.price_formatted}}</span>
            <vi-button @click="selectVoucher(voucher.id)" class="btn-price">
              {{ $t("buy") }}
            </vi-button>
          </div>
        </div>
      </div>
    </div>
  </b-container>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from "vuex";
import ViSpinner from "../../components/common/ViSpinner";
import ViButton from "../../components/common/ViButton.vue";
import {priceFormatter} from "../../utils/common/common";

export default {
  components: {
    ViButton,
    ViSpinner,
  },
  filters: {
    priceFormat: (price, format) => priceFormatter(price, format),
  },
  data() {
    return {
      idModal: "specialInfoModal",
    };
  },
  computed: {
    ...mapGetters([
      "salonId",
      "isLoading",
      "loadingApp",
      "voucherInfo",
      "isVoucherEnabled",
      "getPriceFormat",
      "selectedIds",
    ]),
    vouchers() {
      const queryId = this.selectedIds.voucherId || this.$route.query.voucher;
      const vouchers = this.$store.getters.vouchers

      return vouchers.map(i => ({...i, active: i.id === Number(queryId)}));
    }
  },
  async mounted() {
    await this.$nextTick()

    if(!this.loadingApp && !this.isVoucherEnabled) {
      this.$router.push({ name: "home" });
    } else {
      await this.fetchVouchers({id: this.salonId})
    }
  },
  methods: {
    ...mapMutations([
      "setChosenPackage",
      "setVoucherId",
      "setFormType",
    ]),
    ...mapActions([
      "fetchVouchers",
    ]),
    selectVoucher(id) {
      this.$store.commit("setSelectedIds");
      this.setFormType('else')
      this.setVoucherId(id)
      this.$router.push({name: 'voucher-detail'})
    }
  }
};
</script>
