<template>
  <b-container>
    <div class="vi-main-wrapper--inner">
      <div class="vi-voucher-finish">
        <div class="vi-voucher-finish__body">
          <div class="vi-voucher-finish-alert">
            <img :src="imageUrlWrapper(successIcon)" alt="imgSuccess" />
            <h2>{{$t('order_received')}}</h2>
            <p>{{$t('thank_you_for_the_order_with_payment', {amount: priceFormatMethod(purchasedVoucher.amount, getPriceFormat)})}}</p>

            <p v-if="voucherForm.type === 'else'">{{$t('email_sent_to_a_moment_ago', {email: voucherForm.recipient_email})}}</p>
            <p v-if="voucherForm.type === 'self'">{{$t('email_sent_to_a_moment_ago', {email: getCustomer.email})}}</p>

            <h4 v-if="purchasedVoucher">{{$t('gift_card')}} {{purchasedVoucher.amount | priceFormat(getPriceFormat)}}</h4>
          </div>
          <div v-if="voucherForm.type === 'else'" class="vi-voucher-finish-info">
            <div>
              <span class="vi-voucher-finish-info__label">{{$t('your_name')}}</span>
              <p class="vi-voucher-finish-info__title">{{voucherForm.sender_name}}</p>
            </div>
            <div>
              <span class="vi-voucher-finish-info__label">{{$t('recipient_name')}}</span>
              <p class="vi-voucher-finish-info__title">{{voucherForm.recipient_name}}</p>
            </div>
            <div>
              <span class="vi-voucher-finish-info__label">{{$t('delivery_method')}}</span>
              <p class="vi-voucher-finish-info__title">{{$t('email_to', {email: voucherForm.recipient_email})}}</p>
            </div>
          </div>
        </div>
        <div class="vi-voucher-finish__footer">
          <div class="row justify-content-center">
            <div class="col-12 col-md-4">
              <div class="vi-voucher-finish__footer-inner">
                <vi-button @click="download" vi-full class="mb-3">
                  {{ $t("download_gift_card") }}
                </vi-button>
                <vi-button @click="newOrder" vi-variant="light" vi-full>
                  {{ $t("make_a_new_order") }}
                </vi-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </b-container>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from "vuex";
import ViButton from "../../components/common/ViButton.vue";
import {imageWrapper, priceFormatter} from "../../utils/common/common";
import successIcon from "../../assets/img/success.svg";

export default {
  components: {
    ViButton
  },
  filters: {
    priceFormat: (price, format) => priceFormatter(price, format),
  },
  data() {
    return {
      successIcon,
      idModal: "specialInfoModal",
    };
  },
  computed: {
    ...mapGetters([
      "salonId",
      "getCustomer",
      "voucherForm",
      "purchasedVoucher",
      "getPriceFormat",
    ])
  },
  mounted() {
    if(!this.purchasedVoucher) {
      this.$router.push({name: 'vouchers'})
    }
  },
  methods: {
    ...mapMutations([
      "resetForm",
    ]),
    ...mapActions([
        "downloadVoucher",
    ]),
    priceFormatMethod(price, format) {
      return priceFormatter(price, format)
    },
    imageUrlWrapper(url) {
      return imageWrapper(url);
    },
    newOrder() {
      this.resetForm()
      this.$router.push({name: 'vouchers'})
    },
    async download() {
      try {
        await this.downloadVoucher({
          venueId: this.salonId,
          id: this.purchasedVoucher.uuid,
          code: this.purchasedVoucher.code
        })
      } catch (e) {}
    }
  }
};
</script>
