import axios from "axios";
import {apiUrl, formatDateTime} from "../../utils/common/common";

const FORM = {
  id: 0,
  type: 'else',
  payment_id: 0,

  recipient_name: '',
  recipient_email: '',
  sender_name: '',
  message: '',
  send: 'instantly',
  date_send: '',
}

export const vouchersModule = {
  state: () => ({
    isLoading: false,
    vouchers: [],
    your_vouchers: null,
    info: null,
    form: {...FORM},
    purchasedVoucher: null
  }),
  getters: {
    isLoading: (state) => state.isLoading,
    vouchers: state => state.vouchers,
    yourVouchers: state => state.your_vouchers ? state.your_vouchers.data : [],
    yourVouchersPagination: state => state.your_vouchers ? state.your_vouchers : null,
    voucherInfo: state => state.info,
    voucherForm: state => state.form,
    purchasedVoucher: state => state.purchasedVoucher,
  },
  mutations: {
    toggleLoader (state, value) {
      state.isLoading = value;
    },
    buildVouchers (state, payload) {
      state.vouchers = payload.vouchers_options;
    },
    buildVoucherInfo (state, payload) {
      state.info = {
        vouchers_expiration: payload.vouchers_expiration,
        vouchers_enable_discounts: payload.vouchers_enable_discounts,
        vouchers_additional_amount_type: payload.vouchers_additional_amount_type,
        vouchers_additional_amount: payload.vouchers_additional_amount,
        vouchers_policy: payload.vouchers_policy,
      };
    },
    buildYourVouchers (state, payload) {
      state.your_vouchers = payload;
    },
    concatYourVouchers (state, payload) {
      state.your_vouchers = {
        ...payload,
        data: [...state.your_vouchers.data || [], ...payload.data]
      };
    },

    buildForm (state, payload) {
      state.form = {
        ...state.form,
        ...payload
      };
    },
    resetForm (state) {
      state.form = {...FORM};
    },
    resetDetailForm (state) {
      state.form = {
        ...FORM,
        id: state.form.id,
        type: state.form.type,
      };
    },

    setFormType(state, type) {
      state.form.type = type;
    },
    setVoucherId(state, id) {
      state.form.id = id;
    },
    setPurchasedVoucher(state, payload) {
      state.purchasedVoucher = payload;
    }
  },
  actions: {
    fetchYourVouchers ({ commit }, payload) {
      commit("toggleLoader", true);
      return new Promise(async (resolve, reject) => {
        try {
          const response = await axios.get(apiUrl(`booking/my-vouchers/${payload.id}?page=${payload.page}`));

          if(payload.type && payload.type === 'concat') {
            commit("concatYourVouchers", response.data.data);
          } else {
            commit("buildYourVouchers", response.data.data);
          }

          commit("toggleLoader", false);

          resolve(response)
        } catch (e) {
          reject(e)
        } finally {
          commit("toggleLoader", false);
        }
      })
    },
    fetchVouchers ({ commit }, payload) {
      commit("toggleLoader", true);
      axios
        .get(apiUrl(`booking/vouchers/${payload.id}`))
        .then((response) => response.data)
        .then((vouchers) => {
          commit("buildVouchers", vouchers.data);
          commit("buildVoucherInfo", vouchers.data);

          commit("toggleLoader", false);
        })
        .catch((e) => {
          console.log(e);
          commit("toggleLoader", false);
        });
    },
    buyVoucher({ getters, commit }, payload) {
      const form = {
        ...getters.voucherForm,
        date_send: formatDateTime(getters.voucherForm.date_send),
      }

      if(form.send === 'instantly') {
        delete form.date_send;
      }

      return new Promise(async (resolve, reject) => {
        try {
          const response = await axios.post(
            apiUrl(`booking/buy-voucher/${payload.venueId}`),
            {
             ...form,
              payment_id: payload.paymentId,
            }
          );

          if(!!response.data.voucher && !response.data.data) {
            commit('setPurchasedVoucher', response.data.voucher);
          } else {
            resolve(response.data.data);
          }

          resolve('ok');
        } catch (e) {
          reject(e);
        }
      });
    },
    voucherCheckPayment ({ commit }, payload) {
      return new Promise(async (resolve, reject) => {
        try {
          const response = await axios.post(
            apiUrl(`booking/vouchers/check-payment/${payload.venueId}`),
            {
              payment_intent_id: payload.paymentIntentId,
            }
          );
          commit('setPurchasedVoucher', response.data.voucher);

          resolve(response);
        } catch (e) {
          reject(e);
        }
      })
    },
    downloadVoucher({ commit }, payload) {
      return new Promise(async (resolve, reject) => {
        try {
          const response = await axios.post(
            apiUrl(`booking/download-voucher/${payload.venueId}`),
            { id: payload.id },
            {responseType: 'blob'}
          );

          const blob = new Blob([response.data], { type: 'application/pdf' });
          const url = window.URL.createObjectURL(blob);

          const a = document.createElement('a');
          a.href = url;
          a.download = `gift-card-${payload.code}.pdf`;
          document.body.appendChild(a);
          a.click();
          a.remove();
          window.URL.revokeObjectURL(url);

          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    }
  }
};
