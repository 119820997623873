var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container"},[_c('div',{staticClass:"vi-home",class:_vm.mainLogoIsHide ? 'vi-home--hide-logo' : ''},[_c('div',{staticClass:"vi-home--body"},[(!_vm.mainLogoIsHide)?_c('div',{staticClass:"vi-home--body-content"},[(!_vm.widgetOptions.previewVersion)?_c('div',[(_vm.salonIsPremium && _vm.salonLogo)?_c('img',{class:_vm.salonIsPremium || _vm.salonLogo ? 'm-0' : '',attrs:{"src":_vm.salonLogo,"alt":_vm.salonName}}):_vm._e()]):_c('div',{staticClass:"vi-home--preview-logo",class:_vm.widgetOptions.previewLogo ? 'mb-0' : ''},[_c('img',{staticClass:"preview",class:_vm.widgetOptions.previewLogo ? 'mb-0' : '',attrs:{"src":_vm.widgetOptions.previewLogo
                ? _vm.widgetOptions.previewLogo
                : _vm.imageUrlWrapper(_vm.previewLogo),"alt":_vm.salonName}}),(!_vm.widgetOptions.previewLogo)?_c('div',{staticClass:"vi-home--preview-logo--caption"},[_c('p',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t("wl_add_logo")))]),_c('p',[_vm._v(_vm._s(_vm.$t("wl_your_logo_could_be_here")))]),(
                _vm.previewInformation.settings &&
                !_vm.previewInformation.settings.is_premium
              )?_c('a',{staticClass:"vi-btn btn-secondary",attrs:{"href":_vm.previewInformation.settings.premium_link,"target":"_blank"}},[_vm._v(_vm._s(_vm.$t("wl_upgrade")))]):_vm._e()]):_vm._e()]),(!_vm.widgetOptions.previewVersion)?_c('div',[(!_vm.salonIsPremium || !_vm.salonLogo)?_c('h1',[_vm._v(_vm._s(_vm.salonName))]):_vm._e()]):(
            _vm.previewInformation.settings && !_vm.widgetOptions.previewLogo
          )?_c('div',[_c('h1',[_vm._v("\n            "+_vm._s(_vm.previewInformation.settings.title)+"\n          ")])]):_vm._e()]):_vm._e(),_c('div',{staticClass:"btn-wrapper"},[_c('button',{staticClass:"vi-btn btn-light-outline",attrs:{"type":"button"},on:{"click":function($event){!_vm.widgetOptions.previewVersion && _vm.redirect('services')}}},[_vm._v("\n          "+_vm._s(_vm.$t("wl_new_client"))+"\n        ")]),_c('button',{staticClass:"vi-btn btn-light-outline",attrs:{"type":"button"},on:{"click":function($event){!_vm.widgetOptions.previewVersion && _vm.redirectReturningClient()}}},[_vm._v("\n          "+_vm._s(_vm.$t("returning_client"))+"\n        ")]),(_vm.isVoucherEnabled)?_c('button',{staticClass:"vi-btn btn-light-outline",attrs:{"type":"button"},on:{"click":function($event){return _vm.$router.push({name: 'vouchers'})}}},[_vm._v("\n          "+_vm._s(_vm.$t("buy_gifts_cards_and_vouchers"))+"\n        ")]):_vm._e()])]),(!_vm.widgetOptions.previewVersion)?_c('div',{staticClass:"vi-home--footer"},[_c('div',{staticClass:"text-center"},[_c('img',{attrs:{"src":_vm.imageUrlWrapper(_vm.imgLogo),"alt":"Belliata"}})])]):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }