<template>
  <b-container>
    <div v-if="isLoading && !yourVouchers.length" class="vi-voucher__loader">
      <vi-spinner />
    </div>
    <div v-else class="vi-voucher">
      <div class="vi-voucher__body">
        <div class="vi-voucher__list">
          <div v-for="voucher in yourVouchers" :key="voucher.id" class="vi-your-vouchers-item">
            <div class="vi-your-vouchers-item__main">
              <h5 class="vi-your-vouchers-item__title">{{$t('gift_card')}}</h5>
              <p class="vi-your-vouchers-item__price">{{voucher.amount}}</p>
              <p class="vi-your-vouchers-item__date">{{voucher.expiry_date_formatted}}</p>
            </div>
            <div class="vi-your-vouchers-item__additional">
              <div class="vi-your-vouchers-item__additional-row">
                <span class="vi-your-vouchers-item__code">{{voucher.code}}</span>
                <button @click="download(voucher.uuid, voucher.code)" type="button" class="vi-your-vouchers-item__download">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5M16.5 12 12 16.5m0 0L7.5 12m4.5 4.5V3" />
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </div>

        <div v-if="yourVouchersPagination.current_page < yourVouchersPagination.last_page" class="text-center py-2">
          <b-spinner v-if="isLoading && !!yourVouchers.length" variant="success" class="vi-spinner" />
          <a v-else href="#" class="vi-link-primary" @click.prevent="loadMore(yourVouchersPagination.current_page + 1)">{{
              $t("more")
            }}</a>
        </div>
      </div>
    </div>
  </b-container>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from "vuex";
import ViSpinner from "../../components/common/ViSpinner";
import ViButton from "../../components/common/ViButton.vue";
import {priceFormatter, removeAuthData} from "../../utils/common/common";

export default {
  components: {
    ViButton,
    ViSpinner,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters([
      "salonId",
      "yourVouchers",
      "isLoading",
      "yourVouchersPagination",
    ])
  },
  async mounted() {
    try {
      await this.$nextTick()
      await this.fetchYourVouchers({id: this.salonId, page: 1})
    } catch (e) {
      console.log(e)
      if (e.response && e.response.status === 403) {
        this.logout();
      }
    }
  },
  methods: {
    ...mapMutations([
      "setChosenPackage",
      "setVoucherId",
      "setLoggedCustomer",
      "setCustomerData",
    ]),
    ...mapActions([
      "fetchYourVouchers",
      "downloadVoucher",
    ]),
    logout() {
      removeAuthData();
      this.$router.push({name: "logged-out"});
      this.setLoggedCustomer(false);
      this.setCustomerData(null);
    },
    async download(id, code) {
      try {
        await this.downloadVoucher({
          venueId: this.salonId,
          id,
          code
        })
      } catch (e) {}
    },
    async loadMore(page) {
      try {
        await this.fetchYourVouchers({id: this.salonId, page: page, type: 'concat'})
      } catch (e) {
        console.log(e)
        if (e.response && e.response.status === 403) {
          this.logout();
        }
      }
    }
  }
};
</script>
