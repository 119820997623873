<template>
  <div class="container">
    <div :class="mainLogoIsHide ? 'vi-home--hide-logo' : ''" class="vi-home">
      <div class="vi-home--body">
        <div v-if="!mainLogoIsHide" class="vi-home--body-content">
          <div v-if="!widgetOptions.previewVersion">
            <img
              v-if="salonIsPremium && salonLogo"
              :src="salonLogo"
              :class="salonIsPremium || salonLogo ? 'm-0' : ''"
              :alt="salonName"
            />
          </div>
          <div
            v-else
            :class="widgetOptions.previewLogo ? 'mb-0' : ''"
            class="vi-home--preview-logo"
          >
            <img
              :src="
                widgetOptions.previewLogo
                  ? widgetOptions.previewLogo
                  : imageUrlWrapper(previewLogo)
              "
              :alt="salonName"
              :class="widgetOptions.previewLogo ? 'mb-0' : ''"
              class="preview"
            />
            <div
              v-if="!widgetOptions.previewLogo"
              class="vi-home--preview-logo--caption"
            >
              <p class="title">{{ $t("wl_add_logo") }}</p>
              <p>{{ $t("wl_your_logo_could_be_here") }}</p>
              <a
                v-if="
                  previewInformation.settings &&
                  !previewInformation.settings.is_premium
                "
                :href="previewInformation.settings.premium_link"
                target="_blank"
                class="vi-btn btn-secondary"
                >{{ $t("wl_upgrade") }}</a
              >
            </div>
          </div>
          <div v-if="!widgetOptions.previewVersion">
            <h1 v-if="!salonIsPremium || !salonLogo">{{ salonName }}</h1>
          </div>
          <div
            v-else-if="
              previewInformation.settings && !widgetOptions.previewLogo
            "
          >
            <h1>
              {{ previewInformation.settings.title }}
            </h1>
          </div>
        </div>

        <div class="btn-wrapper">
          <button
            class="vi-btn btn-light-outline"
            type="button"
            @click="!widgetOptions.previewVersion && redirect('services')"
          >
            {{ $t("wl_new_client") }}
          </button>
          <button
            type="button"
            class="vi-btn btn-light-outline"
            @click="!widgetOptions.previewVersion && redirectReturningClient()"
          >
            {{ $t("returning_client") }}
          </button>

          <button
              v-if="isVoucherEnabled"
              type="button"
              class="vi-btn btn-light-outline"
              @click="$router.push({name: 'vouchers'})"
          >
            {{ $t("buy_gifts_cards_and_vouchers") }}
          </button>
        </div>
      </div>
      <div v-if="!widgetOptions.previewVersion" class="vi-home--footer">
        <div class="text-center">
          <img :src="imageUrlWrapper(imgLogo)" alt="Belliata" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { i18n } from "../i18n";
import { mapGetters, mapMutations } from "vuex";
import imgLogo from "../assets/img/logo-dark.svg";
import previewLogo from "../assets/img/icon-preview-belliata.svg";
import { ValidationProvider, extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";
import {
  getReturningClient,
  getToken,
  imageWrapper
} from "../utils/common/common.js";

extend("required", {
  ...required,
  message: i18n.t("this_field_is_required")
});

export default {
  components: {
    ValidationProvider
  },
  data() {
    return {
      imgLogo,
      previewLogo
    };
  },
  computed: {
    ...mapGetters([
      "salonName",
      "salonIsPremium",
      "salonLogo",
      "getCustomer",
      "salonId",
      "widgetOptions",
      "mainLogoIsHide",
      "isVoucherEnabled",
      "previewInformation"
    ])
  },
  created() {
    if (getReturningClient() === 1 && getToken()) {
      this.redirect("returning-client");
    }
  },
  methods: {
    ...mapMutations(["setLoginFromPath"]),
    imageUrlWrapper(url) {
      return imageWrapper(url);
    },
    redirectToServices() {
      this.setLoginFromPath("services");
    },
    redirectReturningClient() {
      this.setLoginFromPath("returning-client");
      if (this.getCustomer) {
        this.redirect("returning-client");
      } else {
        this.$router.push({ name: "login" });
      }
    },
    redirect(url) {
      this.$router.push({ name: url });
    }
  }
};
</script>
