<template>
  <b-container>
    <div class="vi-main-wrapper--inner">
      <BasketCard
        :date="getTimeOrder.date | filterDate"
        :time="getTimeOrder.time_formatted"
        :salon="salonName"
        :address="salonAddress"
        :data="getStableServices.services"
        :packages="getStableServices.packages"
      />

      <Authorization v-if="!isLoggedCustomer" />

      <div v-else class="vi-basket">
        <div v-if="loading" class="vi-loader-static">
          <b-spinner variant="success" class="vi-spinner" />
        </div>
        <div v-else class="vi-basket--inner">
          <div class="vi-basket-list">
            <div
              v-for="packageItem in getStableServices.packages"
              :key="packageItem.id"
              class="vi-card-basket-package"
            >
              <span
                class="vi-card-basket-finish--info-remove"
                @click="
                  removePackage({
                    package_id: packageItem.id,
                    total_price: packageItem.total_price,
                    total_duration: packageItem.total_duration
                  })
                "
                ><i class="ic-close"
              /></span>
              <div class="vi-card-basket-package__header">
                <span class="vi-card-basket-package__header-title">{{
                  packageItem.title
                }}</span>
                <div class="vi-card-basket-package__header-prices">
                  <span class="duration">{{
                    packageItem.total_duration | timeFormat
                  }}</span>
                  <span class="price">{{
                    packageItem.total_price | defaultPriceFormat(getPriceFormat)
                  }}</span>
                </div>
              </div>
              <div class="vi-card-basket-package__body">
                <div
                  v-for="service in packageItem.services"
                  :key="service.id"
                  class="vi-card-basket-finish"
                >
                  <div class="vi-card-basket-finish--caption">
                    <h3>{{ service.title }}</h3>
                    <div class="prices">
                      <span
                        v-if="
                          service.variants[0].custom_price &&
                          service.variants[0].custom_price !==
                            service.variants[0].price
                        "
                        class="old-price"
                        >{{ service.variants[0].price_formatted }}</span
                      >
                      <span class="price">{{
                        service.variants[0].custom_price
                          ? service.variants[0].custom_price
                          : service.variants[0].price
                            | defaultPriceFormat(getPriceFormat)
                      }}</span>
                    </div>
                  </div>
                  <div class="vi-card-basket-finish--info">
                    <div>
                      <span class="vi-card-basket-finish--info-employee">
                        {{ service.employee.first_name }}
                        {{ service.employee.last_name }}
                      </span>
                      <span class="vi-card-basket-finish--info-time">
                        <i class="ic-vi-clock"></i
                        >{{ service.time_formatted }} ({{
                          service.variants[0].custom_duration
                            ? service.variants[0].custom_duration
                            : service.variants[0].duration | timeFormat
                        }})
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              v-for="service in getStableServices.services"
              v-if="!service.package_id"
              :key="service.id"
              class="vi-card-basket-finish"
            >
              <div class="vi-card-basket-finish--caption">
                <h3>{{ service.title }}</h3>
                <span class="price">{{
                  service.variants[0].price_formatted
                }}</span>
              </div>
              <div class="vi-card-basket-finish--info">
                <div>
                  <span class="vi-card-basket-finish--info-employee">
                    {{ service.employee.first_name }}
                    {{ service.employee.last_name }}
                  </span>
                  <span class="vi-card-basket-finish--info-time">
                    <i class="ic-vi-clock"></i>{{ service.time_formatted }} ({{
                      service.variants[0].duration | timeFormat
                    }})
                  </span>
                </div>
                <span
                  v-if="!service.package_id"
                  class="vi-card-basket-finish--info-remove"
                  @click="removeService(service)"
                  ><i class="ic-close"
                /></span>
              </div>
            </div>
          </div>
          <div class="vi-basket-total">
            <span class="vi-link-primary icon-left" @click="openNoteModal">
              <i v-if="getBookingNote.length > 0" class="ic-vi-edit" />
              <i v-else class="ic-vi-plus-circle" />
              {{ getBookingNote.length > 0 ? $t("edit_note") : $t("add_note") }}
            </span>
            <div class="vi-basket-total--label">
              {{ $t("total") }}:
              <strong>{{
                getTotalServicesPrice
                  | priceFormat(getPriceFormat, getChosenServices)
              }}</strong>
            </div>
          </div>
          <div
            v-if="
              isPayments &&
              (paymentVariant === 'part_percent' ||
                paymentVariant === 'part_value')
            "
            class="vi-basket-comment"
          >
            <p class="title">{{ $t("this_service_requires_a_prepayment") }}</p>
            <p>
              {{ deposit | priceFormat(getPriceFormat, getChosenServices) }}
              {{ $t("will_be_charged_to_your_card_now") }}
            </p>
          </div>
          <div v-else-if="paymentVariant === 'card' && isPayments">
            <div v-if="isLoadingPaymentCustomer" class="vi-loader-static">
              <b-spinner variant="success" class="vi-spinner" />
            </div>
            <div v-else>
              <div v-if="paymentMethod">
                <payment-method
                  :data="paymentMethod ? paymentMethod : null"
                  @open="showAddCardModal"
                />
              </div>
              <div v-else class="vi-basket-comment">
                <p class="title">
                  {{ $t("this_service_requires_the_addition_od_a_card") }}
                </p>
                <p>
                  {{ $t("you_must_add_a_card_to_continue_booking") }}
                </p>
                <div class="text-center">
                  <vi-button @click="showAddCardModal">
                    {{ $t("add_card") }}
                  </vi-button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          v-if="!isLoadingPaymentCustomer"
          :class="controlsOverlay ? 'inside-controls' : ''"
          class="vi-appointment-controls"
        >
          <div class="vi-appointment-controls--fix-width">
            <div class="row justify-content-center">
              <div
                v-if="
                  isPayments &&
                  getTotalServicesPrice > 0 &&
                  paymentVariant !== 'card'
                "
                class="col-md-12"
              >
                <div
                  v-if="
                    paymentVariant === 'part_percent' ||
                    paymentVariant === 'part_value'
                  "
                  class="
                    row
                    justify-content-center
                    vi-main-services--footer vi-main-services--footer-first
                  "
                >
                  <div class="col-auto">
                    <span class="vi-main-services--footer-total--services">{{
                      $t("deposit")
                    }}</span>
                    <p class="vi-main-services--footer-total--price">
                      {{
                        deposit | priceFormat(getPriceFormat, getChosenServices)
                      }}
                    </p>
                  </div>
                  <div class="col vi-main-services--footer-action">
                    <div>
                      <vi-button
                        :vi-full="true"
                        @click="handlerToSelectPaymentVariant('deposit')"
                      >
                        {{ $t("pay_deposit") }}
                      </vi-button>
                    </div>
                  </div>
                </div>
                <div
                  class="row justify-content-center vi-main-services--footer"
                >
                  <div class="col-auto">
                    <span class="vi-main-services--footer-total--services">{{
                      $t("full_amount")
                    }}</span>
                    <p class="vi-main-services--footer-total--price">
                      {{
                        getTotalServicesPrice
                          | priceFormat(getPriceFormat, getChosenServices)
                      }}
                    </p>
                  </div>
                  <div class="col vi-main-services--footer-action">
                    <div>
                      <vi-button
                        :vi-full="true"
                        @click="handlerToSelectPaymentVariant('full')"
                      >
                        {{ $t("pay_full_amount") }}
                      </vi-button>
                    </div>
                  </div>
                </div>
              </div>
              <div v-else class="col-md-12">
                <vi-button
                  :vi-loader="loadingBtn"
                  :vi-full="true"
                  :vi-disable="
                    paymentVariant === 'card' && !paymentMethod && isPayments
                  "
                  @click="saveBooking"
                >
                  {{ $t("book") }}
                </vi-button>
              </div>
            </div>
            <div class="text-center vi-text-primary">
              <p class="pt-3 mb-0 fz-12">
                {{ $t("by_booking_your_agree_to_our") }}
              </p>
              <span class="vi-link-primary fz-12" @click="openPrivacyPolice">
                {{ $t("cancellation_policy") }}
              </span>
            </div>
          </div>
        </div>
      </div>

      <Modal :id="idModalAddCard" :title="titleModalAddCard">
        <create-payment-method @success="successfullyAddedMethod" />
      </Modal>
      <Modal :id="idModalNote" :title="titleModalNote">
        <div class="vi-add-note">
          <div class="vi-add-note--body">
            <div class="vi-add-note--title">
              <span class="title"></span>
              <span class="number-words">{{ noteLength }}/500</span>
            </div>
            <div class="vi-add-note--form">
              <textarea
                :value="getBookingNote"
                name="note"
                cols="30"
                rows="10"
                maxlength="500"
                class="vi-form-control"
                @input="countLength($event.target.value)"
              />
            </div>
          </div>
          <div class="vi-add-note--footer">
            <button class="vi-btn btn-primary btn-full" @click="createNote">
              {{ titleModalNote }}
            </button>
          </div>
        </div>
      </Modal>
      <Modal :id="idModalPolice" :title="titleModalPolice">
        <div class="vi-typography pt-2 pb-2">
          <p>{{ privacyPolicy }}</p>
        </div>
      </Modal>
      <modal-confirm :id="idModalLimit" @hide="hideLimitBooking">
        <div class="vi-confirm-message">
          <div class="vi-confirm-message--image">
            <img :src="imageUrlWrapper(danger)" alt="Remove Icon" />
          </div>
          <div class="vi-confirm-message--title">
            <h4 class="fw-bold">
              {{ $t("unable_to_book") }}
            </h4>
          </div>
          <div class="vi-confirm-message--description">
            <p>
              {{ $t("wl_max_bookings_message") }}
            </p>
          </div>
          <div class="vi-confirm-message--btn text-center w-100">
            <vi-button @click="hideLimitBooking(getStableServices.services[0])">
              {{ $t("ok") }}
            </vi-button>
          </div>
        </div>
      </modal-confirm>
    </div>
  </b-container>
</template>

<script>
import Authorization from "@/components/Authorization/Authorization.vue";
import BasketCard from "@/components/BasketCard/BasketCard.vue";
import Modal from "@/components/Modal/Modal.vue";
import danger from "../../assets/img/error.svg";
import warning from "../../assets/img/icon-attantion.svg";
import {
  apiUrl,
  imageWrapper,
  priceFormatter,
  removeAuthData,
  timeFormatter
} from "../../utils/common/common";
import { mapGetters, mapMutations } from "vuex";
import moment from "moment";
import "moment-duration-format";
import ModalConfirm from "../../components/Modal/ModalConfirm";
import ViFragmentIconText from "../../components/common/ViFragmentIconText";
import ViButton from "../../components/common/ViButton";
import PaymentMethod from "../../components/Payment/PaymentMethod";
import CreatePaymentMethod from "../../components/Payment/CreatePaymentMethod";

export default {
  components: {
    PaymentMethod,
    ViButton,
    ViFragmentIconText,
    CreatePaymentMethod,
    Authorization,
    BasketCard,
    ModalConfirm,
    Modal
  },
  filters: {
    filterDate(value) {
      return moment(value).format("DD MMM, YYYY");
    },
    totalPrice(data, format) {
      let total = 0;
      data.map((item) => {
        total = total + item.variants[0].price;
      });
      return priceFormatter(total, format);
    },
    priceFormat(data, format, containsArray) {
      let arrHide = containsArray.filter((item) => {
        if (Object.values(item).includes("hide_price")) {
          return item;
        }
      });
      let arrBookable = containsArray.filter((item) => {
        if (Object.values(item).includes("bookable")) {
          return item;
        }
      });

      if (arrHide.length > 0 && arrBookable.length > 0) {
        return `from ${priceFormatter(data, format)}`;
      } else if (arrHide.length === 0) {
        return priceFormatter(data, format);
      } else {
        return arrHide[0].variants[0].price_formatted;
      }
    },
    defaultPriceFormat(amount, format) {
      return priceFormatter(amount, format);
    },
    timeFormat(data) {
      return timeFormatter(data);
    }
  },
  data() {
    return {
      danger,
      warning,
      isLoadingPaymentCustomer: false,
      idModalAddCard: "addCardModal",
      titleModalAddCard: "Add card",
      idModalPolice: "policeModal",
      titleModalPolice: "Privacy Police",
      idModalNote: "addNoteModal",
      titleModalNote: "Add Note",
      idModalLimit: "limitBookingModal",
      loadingBtn: false,
      isLogged: false,
      paymentMethod: null,
      note: "",
      basket: [],
      savedServices: [],
      noteLength: 0
    };
  },
  computed: {
    ...mapGetters([
      "loading",
      "salonId",
      "salonName",
      "deposit",
      "salonAddress",
      "isPayments",
      "limitBooking",
      "paymentVariant",
      "getBookingNote",
      "privacyPolicy",
      "isLoggedCustomer",
      "getPriceFormat",
      "getTotalServicesPrice",
      "getServicesBasket",
      "getChosenServices",
      "loadingButton",
      "controlsOverlay",
      "getTimeOrder"
    ]),
    getStableServices() {
      let arr1 = this.$store.getters.getChosenServices;
      let arr2 = this.$store.getters.getSlotsFromBasket;
      let arr3 = this.$store.getters.getServicesBasket;
      let arr4 = this.$store.getters.getSalonEmployees;

      let services = [];

      let getEmployeeId = (serviceId) => {
        const service = arr3.filter((time) => time.variant_id === serviceId)[0];
        return service ? service.employee_id : 0;
      };

      if (arr1.length !== 0) {
        const fitToLocalBasket = [];
        const fitToLocalServices = [];

        services = arr1.map((item) => {
          const employee = arr4.filter(
            (i) => i.id === getEmployeeId(item.variants[0].id)
          )[0];
          const basket = arr3.filter(
            (i) => i.variant_id === item.variants[0].id
          )[0];
          const time = arr2.filter(
            (i) => i.variant_id === item.variants[0].id
          )[0];

          if (employee && basket && time) {
            if (item.package_id) {
              fitToLocalBasket.push({
                employee_id: employee.id,
                package_id: item.package_id,
                service_id: item.id,
                variant_id: item.variants[0].id,
                basket_id: basket.basket_id
              });
              fitToLocalServices.push({
                id: item.id,
                package_id: item.package_id,
                titleGroup: item.titleGroup,
                total_price: item.total_price,
                total_duration: item.total_duration,
                title: item.title,
                price: item.variants[0].price,
                custom_price: item.variants[0].custom_price
                  ? item.variants[0].custom_price
                  : null,
                custom_duration: item.variants[0].custom_duration
                  ? item.variants[0].custom_duration
                  : null,
                priceFormatted: item.variants[0].price_formatted,
                time: time.time,
                time_formatted: time.time_formatted,
                duration: item.variants[0].duration,
                employee: `${employee.first_name} ${employee.last_name}`
              });
            } else {
              fitToLocalBasket.push({
                employee_id: employee.id,
                service_id: item.id,
                variant_id: item.variants[0].id,
                basket_id: basket.basket_id
              });
              fitToLocalServices.push({
                id: item.id,
                title: item.title,
                price: item.variants[0].price,
                priceFormatted: item.variants[0].price_formatted,
                time: time.time,
                time_formatted: time.time_formatted,
                duration: item.variants[0].duration,
                employee: employee
                  ? `${employee.first_name} ${employee.last_name}`
                  : ""
              });
            }
          }

          return {
            ...item,
            employee: employee,
            time: arr2.filter((i) => i.variant_id === item.variants[0].id)[0]
              .time,
            time_formatted: arr2.filter(
              (i) => i.variant_id === item.variants[0].id
            )[0].time_formatted,
            basket_id: arr3.filter((time) => time.service_id === item.id)[0]
              .basket_id,
            token: arr3.filter((time) => time.service_id === item.id)[0].token
          };
        });

        this.addToLocalBasket(fitToLocalBasket);
        this.saveToLocalServices(fitToLocalServices);

        let packagesFiltered = services.filter((i) => i.package_id);
        let packagesId = [
          ...new Set(packagesFiltered.map((i) => i.package_id))
        ];
        const packages = packagesId.map((packageId) => {
          return {
            id: packageId,
            title: services.filter((i) => i.package_id === packageId)[0]
              .titleGroup,
            total_price: services.filter((i) => i.package_id === packageId)[0]
              .total_price,
            total_duration: services.filter(
              (i) => i.package_id === packageId
            )[0].total_duration,
            services: [...services.filter((i) => i.package_id === packageId)]
          };
        });

        return { services, packages };
      } else {
        this.runTimer(false);
        this.redirect("services");
        return { services: [], packages: [] };
      }
    },
    formattedCountdown() {
      return moment
        .duration(this.$store.getters.timer.countdown, "seconds")
        .format("m:ss");
    }
  },
  watch: {
    loadingButton: {
      deep: true,
      handler(newStateLoadingButton) {
        this.loadingBtn = !!newStateLoadingButton;
      }
    },
    limitBooking: {
      deep: true,
      handler(newLimitBooking) {
        if (newLimitBooking.status) {
          this.$bvModal.show(this.idModalLimit);
        } else {
          this.$bvModal.hide(this.idModalLimit);
        }
      }
    }
  },
  mounted() {
    this.titleModalNote = this.$t("add_note");
    this.titleModalPolice = this.$t("privacy_policy");
    this.titleModalAddCard = this.$t("add_card");

    this.isPayments &&
      this.paymentVariant === "card" &&
      this.checkPaymentMethod();

    if (this.getStableServices.services.length === 0) {
      this.redirect("services");
    }
  },
  methods: {
    ...mapMutations([
      "setNote",
      "runTimer",
      "setPaymentIntent",
      "removeChosenServices",
      "removeChosenPackage",
      "setSelectedPaymentVariant",
      "setLoggedCustomer",
      "setCustomerData",
      "setLimitBooking",
      "setTabPastAppointment",
      "setTotalPriceBooking",
      "removeFromBasketServicesByVariant",
      "setSavedServices",
      "setPaymentOption",
    ]),
    redirect(url) {
      this.$router.push({ name: url });
    },
    imageUrlWrapper(url) {
      return imageWrapper(url);
    },
    loader(value) {
      this.isLoadingPaymentCustomer = value;
    },
    showAddCardModal() {
      this.$bvModal.show(this.idModalAddCard);
    },
    hideAddCardModal() {
      this.$bvModal.hide(this.idModalAddCard);
    },
    handlerToSelectPaymentVariant(value) {
      this.setPaymentOption('appointment')
      this.redirect("gratuity");
      this.setSavedServices(this.savedServices);
      this.setSelectedPaymentVariant(value);
    },
    successfullyAddedMethod(paymentMethod) {
      this.hideAddCardModal();
      this.paymentMethod = paymentMethod;
    },

    addToLocalBasket(data) {
      this.basket = [...data];
    },
    saveToLocalServices(data) {
      this.savedServices = [...data];
    },
    hideLimitBooking(item) {
      this.setLimitBooking({ message: "", status: false });
      this.setTabPastAppointment(false);
      this.runTimer(false);
      this.redirect("your-appointments");
      this.$store.dispatch("removeServicesFromBasket", {
        salon: this.salonId,
        token: item.token,
        basket_id: item.basket_id,
        count: "all"
      });
    },
    removeService(item) {
      if (item.serviceType === "single") {
        this.removeChosenServices(item);
      } else {
        this.removeFromBasketServicesByVariant(item);
      }
      this.$store.dispatch("removeServicesFromBasket", {
        salon: this.salonId,
        token: item.token,
        basket_id: item.basket_id
      });
    },

    removePackage(payload) {
      this.removeChosenPackage(payload);
    },

    saveBooking() {
      this.setSavedServices(this.savedServices);
      this.$store.dispatch("saveBooking", {
        salon: this.salonId,
        services: this.basket,
        note: this.getBookingNote,
        payment_method_id: this.paymentMethod ? this.paymentMethod.id : ""
      });
      this.setNote("");
      this.noteLength = 0;
    },

    // Check Payment Data
    checkPaymentMethod() {
      return new Promise(async (resolve, reject) => {
        this.loader(true);
        try {
          let response = await this.axios.get(
            apiUrl(`booking/payment-data/${this.salonId}`)
          );

          this.paymentMethod = response.data.payment_method
            ? response.data.payment_method
            : null;
          this.loader(false);

          resolve();
        } catch (e) {
          this.loader(false);
          if (e.response && e.response.status === 403) {
            this.logout();
          } else {
            console.log(e);
          }
          reject(e);
        }
      });
    },

    countLength(value) {
      this.noteLength = value.length;
      this.setNote(value);
    },
    openPrivacyPolice() {
      this.$bvModal.show(this.idModalPolice);
    },
    openNoteModal() {
      this.$bvModal.show(this.idModalNote);
      if (this.noteLength > 0) {
        this.titleModalNote = this.$t("edit_note");
      } else {
        this.titleModalNote = this.$t("add_note");
      }
    },
    createNote() {
      this.$bvModal.hide(this.idModalNote);
    },
    logout() {
      removeAuthData();
      this.setLoggedCustomer(false);
      this.setCustomerData(null);
    }
  }
};
</script>
