<template>
  <div class="vi-main-wrapper--inner">
    <div class="vi-gratuity">
      <div v-if="getPaymentOption === 'appointment'" class="vi-gratuity--amount">
        <div class="vi-gratuity--amount-price">
          <span
            v-if="
              selectedPaymentVariant && selectedPaymentVariant === 'deposit'
            "
          >
            {{ deposit | priceFormat(getPriceFormat, getChosenServices) }}
          </span>
          <span v-else>
            {{
              gratuity.selected.type === "percent"
                ? getTotalServicesPrice +
                  (getTotalServicesPrice / 100) * gratuity.selected.value
                : (getTotalServicesPrice + Number(gratuity.selected.value))
                  | priceFormat(getPriceFormat, getChosenServices)
            }}
          </span>
        </div>
        <div class="vi-gratuity--amount-label">
          <span v-if="gratuity.selected.value > 0">
            {{
              getTotalServicesPrice
                | priceFormat(getPriceFormat, getChosenServices)
            }}{{
              gratuity.selected.value > 0
                ? ` + ${printPriceGratuity(
                    gratuity.selected.value,
                    getTotalServicesPrice,
                    gratuity.selected.type,
                    getPriceFormat
                  )} ${$t("gratuity")}`
                : ``
            }}
          </span>
          <span v-else>{{ $t("amount_due") }}</span>
        </div>
      </div>

      <div v-if="getPaymentOption === 'voucher'" class="vi-gratuity--amount">
        <div class="vi-gratuity--amount-price">
          <span>
            {{ currentVoucher.price_formatted }}
          </span>
        </div>
        <div class="vi-gratuity--amount-label">
          <span>{{ $t("amount_due") }}</span>
        </div>
      </div>

      <div v-if="isLoading" class="vi-loader-static">
        <b-spinner variant="success" class="vi-spinner" />
      </div>
      <div v-else>
        <div class="vi-gratuity--method">
          <b-container>
            <payment-method
              :data="paymentMethod ? paymentMethod : null"
              @open="showAddCardModal"
            />
          </b-container>
        </div>
        <div v-if="errorPaymentMessage" class="error-message">
          {{errorPaymentMessage}}
        </div>
        <div
          v-if="selectedPaymentVariant && selectedPaymentVariant === 'full'"
          class="vi-gratuity--variants"
        >
          <!--
          <b-container>
            <div :class="!paymentMethod ? 'disable-content' : ''">
              <h3 class="vi-gratuity-title">{{ $t("add_gratuity") }}</h3>
              <div
                v-if="getTotalServicesPrice > 0"
                class="vi-gratuity--variants-list"
              >
                <div
                  v-for="item in gratuity.list"
                  :key="item.id"
                  :class="gratuity.selected.id === item.id ? 'active' : ''"
                  class="vi-gratuity--variants-item"
                >
                  <div
                    class="vi-gratuity--variants-item--inner"
                    @click="
                      selectGratuity({
                        id: item.id,
                        type: item.type,
                        value: item.value
                      })
                    "
                  >
                    <span class="percent">{{ item.value }}%</span>
                    <span class="price">{{
                      getTotalServicesPrice
                        | priceFormatGratuity(item.value, getPriceFormat)
                    }}</span>
                  </div>
                </div>
              </div>

              <vi-button
                :vi-variant="
                  gratuity.selected && gratuity.selected.id === 'custom'
                    ? 'default'
                    : 'outline'
                "
                :vi-full="true"
                @click="customerGratuity"
              >
                $ {{ $t("custom_gratuity") }}
              </vi-button>
              <vi-button
                :vi-variant="
                  gratuity.selected && gratuity.selected.id === 'no_gratuity'
                    ? 'default'
                    : 'outline'
                "
                :vi-full="true"
                @click="
                  selectGratuity({
                    id: 'no_gratuity',
                    type: 'percent',
                    value: 0
                  })
                "
              >
                {{ $t("no_gratuity") }}
              </vi-button>
            </div>
          </b-container>
          -->
        </div>
      </div>

      <b-container>
        <div
          :class="controlsOverlay ? 'inside-controls' : ''"
          class="vi-appointment-controls"
        >
          <div v-if="!isLoading" class="vi-appointment-controls--fix-width">
            <div class="row justify-content-center">
              <div v-if="paymentMethod" class="col-md-12">
                <vi-button vi-full @click="openConfirmPaymentModal">
                  {{ $t("confirm_payment") }}
                </vi-button>
              </div>
              <div v-else class="col-md-12">
                <vi-button vi-full @click="showAddCardModal">
                  {{ $t("add_card") }}
                </vi-button>
              </div>
            </div>
            <div v-if="paymentMethod" class="text-center vi-text-primary">
              <p
                v-if="selectedPaymentVariant === 'deposit'"
                class="pt-3 mb-0 fz-12"
              >
                {{
                  $t("i_authorize_you_to_charge_deposit_to_my_card_ending", {
                    deposit: depositPriceFormat,
                    card: paymentMethod.last4
                  })
                }}
              </p>
              <p v-else class="pt-3 mb-0 fz-12">
                {{
                  $t("i_authorize_you_to_charge_deposit_to_my_card_ending", {
                    deposit:
                      gratuity.selected.type === "percent"
                        ? getTotalServicesPrice +
                          (getTotalServicesPrice / 100) *
                            gratuity.selected.value
                        : (getTotalServicesPrice +
                            Number(gratuity.selected.value))
                          | priceFormat(getPriceFormat, getChosenServices),
                    card: paymentMethod.last4
                  })
                }}
              </p>
            </div>
          </div>
        </div>
      </b-container>
    </div>

    <Modal :id="idModalAddCard" :title="titleModalAddCard">
      <create-payment-method @success="successfullyAddedMethod" />
    </Modal>
    <Modal
      :id="idModalIframeRedirect"
      :title="''"
      @close="closeConfirmPaymentModal"
    >
      <iframe
        ref="iframeSDK"
        :src="SDKRedirect"
        frameborder="0"
        width="100%"
        height="100%"
      />
    </Modal>
    <modal-confirm :id="idModalGratuity">
      <div class="vi-confirm-message">
        <div class="vi-confirm-message--title">
          <h4 class="fw-bold">{{ $t("add_custom_gratuity") }}</h4>
        </div>
        <div class="vi-customer-gratuity">
          <span class="vi-form-label">
            {{
              !gratuity.custom.isPercent
                ? $t("custom_percent")
                : $t("custom_amout")
            }}
          </span>
          <div class="vi-form-group">
            <input
              :placeholder="!gratuity.custom.isPercent ? '0%' : '$0.00'"
              v-model="gratuity.custom.value"
              type="text"
              class="vi-form-control"
            />
          </div>
          <div class="vi-customer-gratuity--switcher">
            <label for="percentPrice" class="vi-switcher vi-switcher-which">
              <input
                id="percentPrice"
                v-model="gratuity.custom.isPercent"
                type="checkbox"
              />
              <span class="vi-switcher--label first-variant">%</span>
              <span class="vi-switcher--icon"></span>
              <span class="vi-switcher--label second-variant">$</span>
            </label>
          </div>

          <vi-button
            :vi-class="'mb-2'"
            :vi-full="true"
            :vi-disable="!gratuity.custom.value"
            @click="
              selectGratuity({
                id: 'custom',
                type: !gratuity.custom.isPercent ? 'percent' : 'amount',
                value: gratuity.custom.value
              })
            "
            >{{ $t("add") }}
          </vi-button>
          <vi-button
            :vi-variant="'light'"
            :vi-full="true"
            @click="$bvModal.hide(idModalGratuity)"
            >{{ $t("close") }}
          </vi-button>
        </div>
      </div>
    </modal-confirm>
    <!-- Modal Confirm Payment Method -->
    <modal-confirm :id="idModalConfirmPayment">
      <div class="vi-confirm-message">
        <div v-if="isPaymentLoading" class="vi-loader-content">
          <b-spinner variant="success" class="vi-spinner" />
        </div>
        <div class="vi-confirm-message--image">
          <img :src="imageUrlWrapper(warning)" alt="Warning Icon" />
        </div>
        <div class="vi-confirm-message--title">
          <h4 class="fw-bold">
            {{ $t("are_you_sure_you_want_to_make_a_payment") }}
          </h4>
        </div>
        <div class="vi-confirm-message--btn">
          <vi-button
            :vi-variant="'danger'"
            :vi-class="'btn-w-75'"
            @click="closeConfirmPaymentModal"
          >
            {{ $t("no") }}
          </vi-button>
          <vi-button :vi-class="'btn-w-75'" @click="sendPaymentMethod()">
            {{ $t("yes") }}
          </vi-button>
        </div>
      </div>
    </modal-confirm>
  </div>
</template>

<script>
import iconVisa from "../../assets/img/visa.svg";
import {
  apiUrl,
  imageWrapper,
  priceFormatter,
  removeAuthData
} from "../../utils/common/common";
import { mapActions, mapGetters, mapMutations } from "vuex";
import PaymentMethod from "../../components/Payment/PaymentMethod";
import ViButton from "../../components/common/ViButton";
import ModalConfirm from "../../components/Modal/ModalConfirm";
import Modal from "../../components/Modal/Modal";
import CreatePaymentMethod from "../../components/Payment/CreatePaymentMethod";
import warning from "../../assets/img/icon-attantion.svg";

export default {
  name: "Gratuity",
  components: {
    CreatePaymentMethod,
    PaymentMethod,
    ViButton,
    ModalConfirm,
    Modal
  },
  filters: {
    priceFormat(data, format, containsArray) {
      let arrHide = containsArray.filter((item) => {
        if (Object.values(item).includes("hide_price")) {
          return item;
        }
      });
      let arrBookable = containsArray.filter((item) => {
        if (Object.values(item).includes("bookable")) {
          return item;
        }
      });

      if (arrHide.length > 0 && arrBookable.length > 0) {
        return `from ${priceFormatter(data, format)}`;
      } else if (arrHide.length === 0) {
        return priceFormatter(data, format);
      } else {
        return arrHide[0].variants[0].price_formatted;
      }
    },
    priceFormatGratuity(price, percent, format) {
      return priceFormatter((percent / 100) * price, format);
    }
  },
  data() {
    return {
      warning,
      iconVisa,
      errorPaymentMessage: '',
      stripe: null,
      intentId: null,
      idModalConfirmPayment: "confirmPayment",
      idModalGratuity: "gratuityModal",
      idModalAddCard: "addCardModal",
      idModalIframeRedirect: "iframeRedirectModal",
      titleModalAddCard: "Add card",
      paymentMethod: null,
      isLoading: false,
      isPaymentLoading: false,
      SDKRedirect: null,
      gratuity: {
        list: [
          {
            id: "proposed_1",
            type: "percent",
            value: 18
          },
          {
            id: "proposed_2",
            type: "percent",
            value: 20
          },
          {
            id: "proposed_3",
            type: "percent",
            value: 25
          }
        ],
        selected: {
          status: null,
          id: null,
          type: "percent",
          value: 0
        },
        custom: {
          isPercent: false,
          value: ""
        }
      }
    };
  },
  computed: {
    ...mapGetters([
      "salonId",
      "deposit",
      "controlsOverlay",
      "isPayments",
      "paymentVariant",
      "selectedPaymentVariant",
      "getPriceFormat",
      "getPaymentIntent",
      "getPaymentIntent",
      "getChosenServices",
      "isLoggedCustomer",
      "getServicesBasket",
      "getTotalServicesPrice",
      "getBookingNote",
      "getPaymentOption",
      "voucherForm",
    ]),
    depositPriceFormat() {
      const deposit = this.$store.getters.deposit;
      const format = this.$store.getters.getPriceFormat;

      return priceFormatter(deposit, format);
    },
    currentVoucher() {
      const vouchers = this.$store.getters.vouchers;

      return vouchers.find((v) => v.id === this.voucherForm.id)
    }
  },
  watch: {
    getPaymentIntent(newGetPaymentIntent) {
      if (newGetPaymentIntent) {
        this.closeConfirmPaymentModal();
      }
    }
  },
  mounted() {
    this.titleModalAddCard = this.$t("add_card");

    if (this.isLoggedCustomer && this.isPayments) {
      this.checkPaymentMethod();
    }

    if(this.getPaymentOption === 'appointment') {
      if (this.getChosenServices.length === 0) {
        this.redirect("services");
      }
    }
  },
  methods: {
    ...mapMutations([
      "runTimer",
      "setTotalPriceBooking",
      "setPaymentIntent",
      "setLoggedCustomer",
      "setCustomerData"
    ]),
    ...mapActions(["sentPayment", "buyVoucher", "voucherCheckPayment"]),
    redirect(url) {
      this.$router.push({ name: url });
    },
    imageUrlWrapper(url) {
      return imageWrapper(url);
    },
    showAddCardModal() {
      this.$bvModal.show(this.idModalAddCard);
    },
    hideAddCardModal() {
      this.$bvModal.hide(this.idModalAddCard);
    },
    loader(value) {
      this.isLoading = value;
    },
    loaderPayment(value) {
      this.isPaymentLoading = value;
    },
    addEventListener() {
      window.addEventListener("message", this.stripePaymentIntent, false);
    },
    removeEventListener() {
      window.removeEventListener("message", this.stripePaymentIntent, false);
    },
    stripePaymentIntent(ev) {
      let data = ev.data.message;
      if (data) {
        if (ev.data.message.payment_intent) {
          if (ev.data.message.payment_intent.status === "succeeded") {
            this.$bvModal.hide(this.idModalIframeRedirect);
            this.removeEventListener();
            this.checkPaymentStatus();
          }
        } else {
          this.removeEventListener();
          this.closeConfirmPaymentModal();
          this.$bvModal.hide(this.idModalIframeRedirect);
        }
      }
    },

    successfullyAddedMethod(paymentMethod) {
      this.hideAddCardModal();
      this.paymentMethod = paymentMethod;
    },

    // Check Payment Data
    checkPaymentMethod() {
      return new Promise(async (resolve, reject) => {
        this.loader(true);
        try {
          let response = await this.axios.get(
            apiUrl(`booking/payment-data/${this.salonId}`)
          );

          this.paymentMethod = response.data.payment_method
            ? response.data.payment_method
            : null;
          this.loader(false);

          resolve();
        } catch (e) {
          this.loader(false);
          if (e.response && e.response.status === 403) {
            this.logout();
          } else {
            console.log(e);
          }
          reject(e);
        }
      });
    },

    sendPaymentMethod() {
      if(this.getPaymentOption === 'appointment') {
        this.appointmentPayment()
      } else {
        this.voucherPayment()
      }
    },

    checkPaymentStatus() {
      return new Promise(async (resolve, reject) => {
        this.loaderPayment(true);
        try {
          const data = {
            id: this.intentId,
            type: this.gratuity.selected.type,
            value: this.gratuity.selected.value,
            payment_variant: this.selectedPaymentVariant
          };
          let response = await this.axios.post(
            apiUrl(`booking/check-status/${this.salonId}`),
            data
          );

          if (response.data.payment_status === "succeeded") {
            this.setPaymentIntent(null);
            this.setTotalPriceBooking({
              total: response.data.total
            });
            this.loaderPayment(false);
            this.runTimer(false);
            this.redirect("booking-created");
          }
          resolve();
        } catch (e) {
          this.loaderPayment(false);
          if (e.response && e.response.status === 403) {
            this.logout();
          } else {
            console.log(e);
          }
          reject(e);
        }
      });
    },

    stripeConfirmation(stripeAccount, clientSecret) {
      return new Promise(async (resolve, reject) => {
        const stripe = window.Stripe(process.env.VUE_APP_STRIPE_API_PUBLISH_KEY, {stripeAccount});

        try {
          const response = await stripe.confirmCardPayment(clientSecret)
          resolve(response.paymentIntent)
        } catch (e) {
          reject(e)
        }
      })
    },

    async voucherPayment() {
      this.loaderPayment(true);
      this.errorPaymentMessage = ''
      try {
        const response = await this.buyVoucher({
          venueId: this.salonId,
          paymentId: this.paymentMethod.id,
        })

        if(response.action && response.action === 'required_action') {
          try {
            const stripeResponse = await this.stripeConfirmation(response.account_id, response.client_secret);
            if(stripeResponse && stripeResponse.id) {
              try {
                await this.voucherCheckPayment({
                  venueId: this.salonId,
                  paymentIntentId: stripeResponse.id,
                })

                this.closeConfirmPaymentModal();
                this.redirect("voucher-finish");
              } catch (e) {
                console.log('Error:', e)

                this.errorPaymentMessage = 'Something went wrong!'
                this.closeConfirmPaymentModal();
              }
            }
            console.log(stripeResponse)
          } catch (e) {
            console.log(e)
          }
        } else {
          this.closeConfirmPaymentModal();
          this.redirect("voucher-finish");
        }
      } catch (e) {
        if (e.response && e.response.status === 403) {
          this.logout();
        } else {
          console.log(e);
        }
      } finally {
        this.loaderPayment(false);
      }
    },
    appointmentPayment() {
      return new Promise(async (resolve, reject) => {
        this.loaderPayment(true);
        try {
          let response = await this.axios.post(
              apiUrl(`booking/payment-data/${this.salonId}`),
              {
                id: this.paymentMethod.id,
                token: this.getServicesBasket[0].token,
                type: this.gratuity.selected.type,
                value: this.gratuity.selected.value,
                payment_variant: this.selectedPaymentVariant,
                note: this.getBookingNote
              }
          );

          if (
              response.data.result &&
              response.data.result.status === "requires_action"
          ) {
            this.SDKRedirect =
                response.data.result.next_action.use_stripe_sdk.stripe_js;
            this.intentId = response.data.result.id;
            this.$bvModal.show(this.idModalIframeRedirect);
            this.loaderPayment(false);
            this.addEventListener();
          } else {
            this.loaderPayment(false);
            this.closeConfirmPaymentModal();
            this.runTimer(false);
            this.setTotalPriceBooking({ total: response.data.total });
            this.setPaymentIntent(response.data);
            this.redirect("booking-created");
          }

          resolve();
        } catch (e) {
          this.loaderPayment(false);
          if (e.response && e.response.status === 403) {
            this.logout();
          } else {
            console.log(e);
          }
          reject(e);
        }
      });
    },

    // Grautuit
    printPriceGratuity(value, total, type, format) {
      if (type === "percent") {
        return priceFormatter((value / 100) * total, format);
      }
      return priceFormatter(value, format);
    },
    selectGratuity(payload) {
      if (payload.id === "custom") {
        this.$bvModal.hide(this.idModalGratuity);
      }

      this.gratuity.selected = {
        ...payload,
        status: "filled"
      };
    },
    customerGratuity() {
      this.$bvModal.show(this.idModalGratuity);
    },

    // Confirm Payment Modal
    openConfirmPaymentModal() {
      this.$bvModal.show(this.idModalConfirmPayment);
    },
    closeConfirmPaymentModal() {
      this.$bvModal.hide(this.idModalConfirmPayment);
    },
    logout() {
      this.redirect("logged-out");
      removeAuthData();
      this.setLoggedCustomer(false);
      this.setCustomerData(null);
    }
  }
};
</script>
