<template>
  <b-container>
    <div class="vi-main-wrapper--inner">
      <div class="wi-voucher-checkout">
        <div class="wi-voucher-checkout__body">
          <div class="wi-voucher-checkout-salon">
            <img v-if="salonIsPremium && salonLogo" :src="salonLogo" :alt="salonName" class="wi-voucher-checkout-salon__logo">
            <h5 class="wi-voucher-checkout-salon__title">{{salonName}}</h5>
            <address class="wi-voucher-checkout-salon__address">{{salonAddress}}</address>
          </div>
          <div class="wi-voucher-checkout-info">
            <p class="wi-voucher-checkout-info__row"><span>{{$t('delivery_method')}}:</span> Email</p>

            <p v-if="voucherForm.type === 'else'" class="wi-voucher-checkout-info__row"><span>{{$t('send_to')}}:</span> {{voucherForm.recipient_name}} ({{voucherForm.recipient_email}})</p>
            <p v-if="voucherForm.type === 'self'" class="wi-voucher-checkout-info__row"><span>{{$t('send_you_to')}}:</span> {{getCustomer.email}}</p>
            <p v-if="voucherForm.message" class="wi-voucher-checkout-info__row"><span>{{$t('message')}}:</span> {{voucherForm.message}}</p>
          </div>
          <div v-if="currentVoucher" class="wi-voucher-checkout-item">
            <div class="wi-voucher-checkout-item__main">
              <h6 class="wi-voucher-checkout-item__main-title">{{$t('gift_card')}}</h6>
              <span class="wi-voucher-checkout-item__main-subtitle">{{ $t(`valid_for_${currentVoucher.valid}`) }}</span>
            </div>
            <div class="wi-voucher-checkout-item__price">
              <span class="wi-voucher-checkout-item__price-new">{{currentVoucher.price_formatted}}</span>
              <span class="wi-voucher-checkout-item__price-old">{{currentVoucher.amount | priceFormat(getPriceFormat)}}</span>
            </div>
          </div>
        </div>
        <div class="wi-voucher-checkout__footer">
          <b-container>
            <div class="wi-voucher-checkout__footer-inner">
              <div class="row justify-content-center">
                <div class="col-12 col-md-4">
                  <vi-button @click="pay" vi-full>
                    {{ $t("pay") }}
                  </vi-button>
                </div>
              </div>

              <div class="wi-voucher-checkout__footer-bottom">
                <p>{{$t('securely_accepts_online_bookings_with', {brand: salonName})}}</p>
                <img :src="imageUrlWrapper(LogoBelliata)" alt="Belliata">
              </div>
            </div>
          </b-container>
        </div>
      </div>
    </div>

  </b-container>
</template>

<script>
import {mapGetters, mapMutations} from "vuex";
import ViSpinner from "../../components/common/ViSpinner";
import ViButton from "../../components/common/ViButton.vue";
import ModalConfirm from "../../components/Modal/ModalConfirm.vue";
import FormInput from "../../components/FormInput/FormInput.vue";
import LogoBelliata from "../../assets/img/logo/belliata-basic.svg";
import {imageWrapper, priceFormatter} from "../../utils/common/common";

export default {
  components: {
    ModalConfirm, FormInput,
    ViButton,
    ViSpinner,
  },
  filters: {
    priceFormat: (price, format) => priceFormatter(price, format),
  },
  data() {
    return {
      LogoBelliata
    };
  },
  computed: {
    ...mapGetters([
      "salonId",
      "isLoading",
      "voucherForm",
      "getPriceFormat",

      "isLoggedCustomer",
      "getCustomer",
      "salonIsPremium",
      "salonName",
      "salonAddress",
      "salonLogo",
    ]),
    currentVoucher() {
      const vouchers = this.$store.getters.vouchers;
      const info = this.$store.getters.voucherInfo;

      return {
        ...vouchers.find((v) => v.id === this.voucherForm.id),
        valid: info.vouchers_expiration
      }
    }
  },
  created() {
    if(!this.isLoggedCustomer) {
      this.$router.push({name: 'auth'})
    }
  },
  mounted() {
    if(!this.voucherForm.id) {
      this.$router.push({name: 'vouchers'})
    }
  },
  methods: {
    ...mapMutations([
      "setChosenPackage",
      "setVoucherId",
      "buildForm",
      "setPaymentOption",
      "setFormInfo",
    ]),
    imageUrlWrapper(url) {
      return imageWrapper(url);
    },
    pay() {
      this.setPaymentOption('voucher')
      this.$router.push({name: 'gratuity'});
    }
  }
};
</script>
