<template>
  <b-container>
    <div class="wi-voucher-details">
      <div class="wi-voucher-details__header">
        <a @click.prevent="setFormType('else')" href="#" class="wi-btn-tab"
           :class="{'active': voucherForm.type === 'else'}">
          <svg class="wi-btn-tab__icon" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
               stroke-width="1.5" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round"
                  d="M15.75 6a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0ZM4.501 20.118a7.5 7.5 0 0 1 14.998 0A17.933 17.933 0 0 1 12 21.75c-2.676 0-5.216-.584-7.499-1.632Z"/>
          </svg>

          <div class="wi-btn-tab__title">{{ $t('someone_else') }}</div>
        </a>
        <a @click.prevent="selectYourSelf" href="#" class="wi-btn-tab" :class="{'active': voucherForm.type === 'self'}">
          <svg class="wi-btn-tab__icon" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
               stroke-width="1.5" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round"
                  d="M21 11.25v8.25a1.5 1.5 0 0 1-1.5 1.5H5.25a1.5 1.5 0 0 1-1.5-1.5v-8.25M12 4.875A2.625 2.625 0 1 0 9.375 7.5H12m0-2.625V7.5m0-2.625A2.625 2.625 0 1 1 14.625 7.5H12m0 0V21m-8.625-9.75h18c.621 0 1.125-.504 1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125h-18c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125Z"/>
          </svg>

          <div class="wi-btn-tab__title">{{ $t('your_self') }}</div>
        </a>
      </div>
      <div class="wi-voucher-details__body">
        <form class="wi-voucher-details-form" @submit.prevent="submit">
          <form-input
              v-model="form.recipient_name"
              :error="errors.recipient_name"
              :last-letter-red="'*'"
              :label="$t('recipient_name')"
              type="text"
              name="recipient_name"
              @validate="validate('recipient_name')"
          />
          <form-input
              v-model="form.recipient_email"
              :error="errors.recipient_email"
              :last-letter-red="'*'"
              :label="$t('recipient_email')"
              type="email"
              name="recipient_email"
              @validate="validate('recipient_email')"
          ></form-input>
          <form-input
              v-model="form.sender_name"
              :error="errors.sender_name"
              :last-letter-red="'*'"
              :label="$t('sender_name')"
              type="text"
              name="sender_name"
              @validate="validate('sender_name')"
          />
          <div class="form-group vi-form-group">
            <label for="message_area" class="vi-form-label">{{ $t('message') }}</label>
            <b-form-textarea
                id="message_area"
                v-model="form.message"
                :placeholder="$t('message')"
                rows="3"
                max-rows="6"
                class="form-control vi-form-control"
            ></b-form-textarea>
          </div>

          <hr class="vi-hr">

          <div class="form-group vi-form-group">
            <b-form-select v-model="form.send" :options="options" class="form-control vi-form-control"></b-form-select>
          </div>

          <form-input
              v-if="form.send === 'plan'"
              v-model="form.date_send"
              :error="errors.date_send"
              type="datetime-local"
              name="date_send"
              @validate="validate('date_send')"
          ></form-input>

          <div class="wi-voucher-details-form__footer">
            <b-container>
              <div class="row justify-content-center align-items-start">
                <div class="col-12 col-md-4">
                  <vi-button v-if="isValidForm" @click="showPreview" class="btn-price mb-3" vi-variant="light" vi-full>
                    {{ $t("preview_voucher") }}
                  </vi-button>
                  <vi-button vi-type="submit" :vi-disable="!isValidForm" vi-full>
                    {{ $t("continue") }}
                  </vi-button>
                </div>
              </div>
            </b-container>
          </div>
        </form>
      </div>
    </div>

    <Modal :id="idModalPreviewVoucher" :title="$t('Preview')" @close="hidePreview">
      <div class="vi-voucher-preview">
        <div class="vi-voucher-preview__header">
          <h5>{{ $t('you_have_a_gift') }}</h5>
        </div>
        <div class="vi-voucher-preview__body">
          <div v-if="currentVoucher" class="wi-voucher-checkout-item mb-4">
            <div class="wi-voucher-checkout-item__main">
              <h6 class="wi-voucher-checkout-item__main-title">{{ $t('gift_card') }}</h6>
              <span class="wi-voucher-checkout-item__main-subtitle">{{ $t(`valid_for_${currentVoucher.valid}`) }}</span>
            </div>
            <div class="wi-voucher-checkout-item__price">
              <span class="wi-voucher-checkout-item__price-new">{{ currentVoucher.price_formatted }}</span>
              <span
                  class="wi-voucher-checkout-item__price-old">{{ currentVoucher.amount | priceFormat(getPriceFormat) }}</span>
            </div>
          </div>

          <div class="mb-4">
            <p class="wi-voucher-checkout-info__row"><span>{{ $t('to') }}:</span> {{ form.recipient_name }}</p>
            <p v-if="getCustomer" class="wi-voucher-checkout-info__row"><span>{{ $t('from') }}:</span>
              {{ getCustomer.first_name }} {{ getCustomer.last_name }}</p>
            <p v-if="form.message" class="wi-voucher-checkout-info__row"><span>{{ $t('message') }}:</span>
              {{ form.message }}</p>
          </div>

          <div class="mb-4">
            <div class="vi-voucher-preview-content">
              <h4 class="vi-voucher-preview-content__title">{{ $t('where_to_use') }}</h4>
              <p>{{ salonName }}</p>
              <p>{{ salonAddress }}</p>
              <p>{{ salonCity }}</p>
              <p>{{ salonZipcode }}</p>
            </div>
            <div class="vi-voucher-preview-content">
              <h4 class="vi-voucher-preview-content__title">{{ $t('how_to_use_voucher') }}</h4>
              <p>{{ $t('how_to_use_voucher_answer') }}</p>
            </div>
            <div class="vi-voucher-preview-content mb-4">
              <h4 class="vi-voucher-preview-content__title">{{ $t('code') }} <span>({{ $t('example') }})</span></h4>
              <p>JJS32KS</p>
            </div>
            <div class="vi-voucher-preview-content">
              <h4 class="vi-voucher-preview-content__subtitle">{{ $t('terms_conditions') }}</h4>
              <div class="vi-content" v-html="voucherInfo.vouchers_policy"></div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  </b-container>
</template>

<script>
import {mapGetters, mapMutations} from "vuex";
import ViSpinner from "../../components/common/ViSpinner";
import ViButton from "../../components/common/ViButton.vue";
import ModalConfirm from "../../components/Modal/ModalConfirm.vue";
import FormInput from "../../components/FormInput/FormInput.vue";
import {object, string} from "yup";
import CreatePaymentMethod from "../../components/Payment/CreatePaymentMethod.vue";
import Modal from "../../components/Modal/Modal.vue";
import {priceFormatter} from "../../utils/common/common";

export default {
  components: {
    Modal, CreatePaymentMethod,
    ModalConfirm, FormInput,
    ViButton,
    ViSpinner,
  },
  filters: {
    priceFormat: (price, format) => priceFormatter(price, format),
  },
  data() {
    return {
      idModalPreviewVoucher: 'preview-voucher',
      form: {
        recipient_name: '',
        sender_name: '',
        recipient_email: '',
        send: 'instantly',
        date_send: '',
        message: '',
      },
      errors: {
        recipient_name: '',
        sender_name: '',
        recipient_email: '',
        date_send: '',
      },
      options: [
        {value: 'instantly', text: this.$t('voucher_instantly')},
        {value: 'plan', text: this.$t('voucher_plan')}
      ]
    };
  },
  computed: {
    ...mapGetters([
      "salonId",
      "isLoading",
      "vouchers",
      "voucherForm",
      "salonAddress",
      "salonCity",
      "salonName",
      "salonZipcode",
      "getCustomer",
      "voucherInfo",
      "getPriceFormat",
    ]),
    VoucherFormSchema() {
      const params = {
        recipient_name: string().required(this.$t("this_field_is_required")),
        sender_name: string().required(this.$t("this_field_is_required")),
        recipient_email: string()
            .email(this.$t("email_must_be_valid"))
            .required(this.$t("this_field_is_required")),
        date_send: string().required(this.$t("this_field_is_required")),
      };

      return object().shape(params);
    },
    isValidForm() {
      return !!this.form.recipient_name && !!this.form.sender_name && !!this.form.recipient_email && !!this.form.send
    },
    currentVoucher() {
      const vouchers = this.$store.getters.vouchers;
      const info = this.$store.getters.voucherInfo;

      return {
        ...vouchers.find((v) => v.id === this.voucherForm.id),
        valid: info.vouchers_expiration
      }
    }
  },
  mounted() {
    if (!this.voucherForm.id) {
      this.$router.push({name: 'vouchers'})
    } else {
      this.form.recipient_name = this.voucherForm.recipient_name;
      this.form.sender_name = this.voucherForm.sender_name;
      this.form.recipient_email = this.voucherForm.recipient_email;
      this.form.send = this.voucherForm.send;
      this.form.date_send = this.voucherForm.date_send;
      this.form.message = this.voucherForm.message;
    }
  },
  methods: {
    ...mapMutations([
      "setChosenPackage",
      "setVoucherId",
      "buildForm",
      "resetDetailForm",
      "setLoginFromPath",
      "setFormType",
    ]),
    validate(field) {
      this.VoucherFormSchema.validateAt(field, this.form)
          .then(() => {
            this.errors[field] = "";
          })
          .catch((err) => {
            this.errors[err.path] = err.message;
          });
    },
    showPreview() {
      this.$bvModal.show(this.idModalPreviewVoucher)
    },
    hidePreview() {
      this.$bvModal.hide(this.idModalPreviewVoucher)
    },
    submit() {
      if (this.isValidForm) {
        this.buildForm(this.form)
        this.setLoginFromPath('voucher-checkout')
        this.$router.push({name: 'voucher-checkout'})
      }
    },
    selectYourSelf() {
      this.resetDetailForm()
      this.setFormType('self')
      this.setLoginFromPath('voucher-checkout')
      this.$router.push({name: 'voucher-checkout'})
    }
  }
};
</script>
